import React from 'react'

import Alert from 'react-bootstrap/Alert'
import ListGroup from 'react-bootstrap/ListGroup'
import Modal from 'react-bootstrap/Modal'

import Button from '../../presentational/Button'

const MenuItemsModal = ({ show, onHide, menuItems, addToPage }) => (
    <Modal show={show} onHide={onHide}>
        <Modal.Header closeButton>
            <Modal.Title>Выберите позицию из списка</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            {menuItems.length
                ? <ListGroup>
                    {menuItems.map((item, index) => (
                        <ListGroup.Item key={'menu-item-item-' + index} action
                                        onClick={() => addToPage(item.id)}
                        >
                            {index + 1}. {item.title} | Цена: {item.price}
                        </ListGroup.Item>
                    ))}
                </ListGroup>
                : <Alert variant="warning">
                    Нет позиций в меню. Сначала добавьте блюда, напитки и другие позиции меню.
                </Alert>
            }
        </Modal.Body>
        <Modal.Footer>
            <Button variant="secondary"
                    onClick={onHide}
            >
                Отмена
            </Button>
        </Modal.Footer>
    </Modal>
);

export default MenuItemsModal;
