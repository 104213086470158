import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'

import { useDispatch, useSelector } from 'react-redux'
import { menuItemsSelector, statusSelector, loadItems } from '../../../app/model/menuItemsSlice'
import { selectMenuPages } from '../../../app/model/menuPagesSlice'

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Carousel from 'react-bootstrap/Carousel'
import Alert from 'react-bootstrap/Alert'

import styles from './MenuPageView.module.css'

const MenuPageView = () => {
    const dispatch = useDispatch();

    const menuItems = useSelector(menuItemsSelector);
    const itemsStatus = useSelector(statusSelector);
    const menuList = useSelector(selectMenuPages);

    useEffect(() => {
        itemsStatus.init && dispatch(loadItems());
    }, [dispatch, itemsStatus]);

    // TODO: handle loading process: itemsStatus.loading
    return (
        <Container className="mt-4 p-4">
            <Row>
                <Col>
                {menuList.length
                    ? <Carousel interval={null}>
                        {menuList.map((page, index) => (
                            <Carousel.Item key={'menu-page-view-' + index}>
                                <Container className="mb-5">
                                    <Row>
                                        <Col sm={1} />
                                        <Col sm={10}>
                                            <div className="m-2 p-3 bg-light">
                                                <h3 className="text-center">{page.title}</h3>
                                                <Container className={styles.items}>
                                                {page.items.length ? page.items.map((itemId, index) => {
                                                    const item = menuItems.find(obj => obj.id === itemId);
                                                    if (!item) {
                                                        // TODO: Something went wrong - log it!
                                                        return '';
                                                    }
                                                    return (
                                                        <Row key={`menu-item-${index}-${itemId}`}>
                                                            <Col sm={10}>
                                                                {item.title}
                                                            </Col>
                                                            <Col sm={2} className="text-right">
                                                                {item.price}
                                                            </Col>
                                                        </Row>
                                                    );
                                                }) : (
                                                    <Row>
                                                        <Col>
                                                            <p>&nbsp;</p>
                                                            <p>&nbsp;</p>
                                                            <p>&nbsp;</p>
                                                        </Col>
                                                    </Row>
                                                )}
                                                </Container>
                                            </div>
                                        </Col>
                                        <Col sm={1} />
                                    </Row>
                                </Container>
                            </Carousel.Item>
                        ))}
                    </Carousel>
                    : <Alert variant="primary">
                        Нет страниц в меню. Сначала <Link to="/menu/pages/add">добавьте страницы</Link>.
                    </Alert>
                }
                </Col>
            </Row>
        </Container>
    );
};

export default MenuPageView;
