import React, { useEffect, useState } from 'react'
import { Formik, FieldArray } from 'formik'
import * as Yup from 'yup'

import { useDispatch, useSelector } from 'react-redux'
import { menuItemsSelector, statusSelector, loadItems } from '../../../app/model/menuItemsSlice'
import { add, edit, getPageById } from '../../../app/model/menuPagesSlice'

import { Redirect } from 'react-router-dom'

import * as Icons from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import ListGroup from 'react-bootstrap/ListGroup'

import Button from '../../presentational/Button'
import DeleteConfirmation from '../../presentational/DeleteConfirmation'

import MenuItemsModal from './MenuItemsModal'

const MenuPageEditForm = ({ id }) => {
    const componentRoute = '/menu/pages';
    const itemId = parseInt(id) || 0;
    const uiId = `menu-page-edit-form-${itemId}`; // Unique string for UI rendering

    const dispatch = useDispatch();

    const menuItems = useSelector(menuItemsSelector);
    const itemsStatus = useSelector(statusSelector);
    const page = useSelector(getPageById(itemId));

    const [itemFormShow, setItemFormShow] = useState(false);
    const [formProcessing, setFormProcessing] = useState(false);
    const [backToList, setBackToList] = useState(false);

    useEffect(() => {
        itemsStatus.init && dispatch(loadItems());
    }, [dispatch, itemsStatus]);

    const handleSubmit = values => {
        setFormProcessing(true);

        // TODO: handleSubmit process should be Promise based
        dispatch(
            itemId ? edit({ ...values, id: itemId }) : add(values)
        );

        setFormProcessing(false);
        setBackToList(true);
    };

    const initialValues = page || {
        title: '',
        items: [],
    };

    if (backToList) {
        return <Redirect to={componentRoute} />
    }

    const hideItemForm = () => setItemFormShow(false);

    const addItem = (arrayHelpers, itemId) => {
        arrayHelpers.push(itemId);
        hideItemForm();
    };

    const deleteItem = (arrayHelpers, index) => {
        arrayHelpers.remove(index);
    };

    const onDragEnd = (arrayHelpers, result) => {
        if (!result.destination) {
            return;
        }
        arrayHelpers.move(result.source.index, result.destination.index);
    };

    // TODO: handle loading process: itemsStatus.loading
    return (
        <Container className="bg-light mt-4 p-4">
            <Row>
                <Col>
                    <h2 className="mb-4">{itemId ? 'Редактировать страницу меню' : 'Новая страница меню'}</h2>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Formik onSubmit={handleSubmit}
                            initialValues={initialValues}
                            validationSchema={Yup.object({
                                title: Yup.string()
                                    .max(40, 'Не более 40 символов')
                                    .required('Обязательное поле'),
                            })}
                    >
                        {formik => (
                            <Form noValidate onSubmit={formik.handleSubmit}>
                                <Form.Group controlId="title">
                                    <Form.Label>Название страницы</Form.Label>
                                    <Form.Control {...formik.getFieldProps('title')}
                                                  type="text"
                                                  placeholder={'Новая страница'}
                                                  isInvalid={!!(formik.touched.title && formik.errors.title)}
                                                  disabled={formProcessing}
                                    />
                                    <Form.Control.Feedback type="invalid">{formik.errors.title}</Form.Control.Feedback>
                                    <Form.Text className="text-muted">Например: &laquo;Десерты&raquo;</Form.Text>
                                </Form.Group>
                                <FieldArray name="items"
                                            render={arrayHelpers => (
                                                <>
                                                    {formik.values.items.length
                                                        ? <DragDropContext onDragEnd={result => onDragEnd(arrayHelpers, result)}>
                                                            <Droppable droppableId={uiId + '-droppable'}>
                                                                {provided => (
                                                                    <div ref={provided.innerRef}
                                                                         {...provided.droppableProps}
                                                                    >
                                                                        <ListGroup className="mb-3">
                                                                        {formik.values.items.map((itemId, index) => {
                                                                            const item = menuItems.find(obj => obj.id === itemId);
                                                                            if (!item) {
                                                                                // TODO: Something went wrong - log it!
                                                                                return '';
                                                                            }
                                                                            return (
                                                                                <Draggable key={`menu-item-${index}-${itemId}`} draggableId={`menu-item-${index}-${itemId}`} index={index}>
                                                                                {provided => (
                                                                                    <div ref={provided.innerRef}
                                                                                         {...provided.draggableProps}
                                                                                         {...provided.dragHandleProps}
                                                                                    >
                                                                                        <ListGroup.Item>
                                                                                            <Row>
                                                                                                <Col sm={10} className="py-1">
                                                                                                    {item.title}
                                                                                                </Col>
                                                                                                <Col sm={1} className="text-right">
                                                                                                    {item.price}
                                                                                                </Col>
                                                                                                <Col sm={1} className="text-right">
                                                                                                    <DeleteConfirmation deleteAction={() => deleteItem(arrayHelpers, index)}
                                                                                                                        title="Удалить позицию"
                                                                                                                        message={`Вы уверены, что хотите удалить '${item.title}' с этой страницы меню?`}
                                                                                                    >
                                                                                                        {(showModal) => (
                                                                                                            <Button variant="outline-secondary" size="sm" className="mx-2"
                                                                                                                    onClick={showModal}
                                                                                                                    disabled={formProcessing}
                                                                                                            >
                                                                                                                <FontAwesomeIcon icon={Icons.faTrashAlt} size="lg" />
                                                                                                            </Button>
                                                                                                        )}
                                                                                                    </DeleteConfirmation>
                                                                                                </Col>
                                                                                            </Row>
                                                                                        </ListGroup.Item>
                                                                                    </div>
                                                                                )}
                                                                                </Draggable>
                                                                            );
                                                                        })}
                                                                        {provided.placeholder}
                                                                        </ListGroup>
                                                                    </div>
                                                                )}
                                                            </Droppable>
                                                        </DragDropContext>
                                                        : ''
                                                    }
                                                    <Form.Group>
                                                        <Button variant="secondary"
                                                                onClick={() => setItemFormShow(true)}
                                                                disabled={formProcessing}
                                                        >
                                                            <FontAwesomeIcon icon={Icons.faPlus} /> Добавить позицию
                                                        </Button>
                                                        <Form.Text className="text-muted">Добавьте содержимое страницы: блюда, напитки и т.д.</Form.Text>
                                                    </Form.Group>
                                                    <MenuItemsModal show={itemFormShow} onHide={hideItemForm} menuItems={menuItems} addToPage={itemId => addItem(arrayHelpers, itemId)} />
                                                    <hr />
                                                </>
                                            )}
                                />
                                <Button type="submit" processing={formProcessing} processingTitle="В процессе...">
                                    {itemId ? 'Сохранить изменения' : 'Добавить страницу'}
                                </Button>
                            </Form>
                        )}
                    </Formik>
                </Col>
            </Row>
        </Container>
    );
};

export default MenuPageEditForm;
