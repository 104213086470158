import React, { useState } from 'react';

import Modal from 'react-bootstrap/Modal';

import Button from '../Button';

const DeleteConfirmation = ({ children, deleteAction, title, message }) => {
    const [modalShow, setModalShow] = useState(false);

    const hideForm = () => setModalShow(false);
    const handleSubmit = () => {
        deleteAction();
        hideForm();
    };

    return (
        <>
            {children(() => setModalShow(true))}
            <Modal show={modalShow} onHide={hideForm}>
                <Modal.Header closeButton>
                    <Modal.Title>{title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{message}</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={hideForm}>Отмена</Button>
                    <Button variant="primary" onClick={handleSubmit} className="w-25">OK</Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default DeleteConfirmation;
