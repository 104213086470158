import { createSlice } from '@reduxjs/toolkit'

export const menuPagesSlice = createSlice({
    name: 'menuPages',
    initialState: {
        pages: [],
    },
    reducers: {
        add: (state, { payload }) => {
            state.pages.push({
                ...payload,
                id: state.pages.length + 1,
            });
        },
        edit: (state, { payload }) => {
            const index = state.pages.findIndex(page => page.id === payload.id);
            index > -1 && (state.pages[index] = payload);
        },
    },
});

export const { add, edit } = menuPagesSlice.actions;

export const selectMenuPages = state => state.menuPages.pages;

export const getPageById = id => state => (
    state.menuPages.pages.find(obj => obj.id === id)
);

export default menuPagesSlice.reducer;
