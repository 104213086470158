import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'

import { useDispatch, useSelector } from 'react-redux'
import { menuItemsSelector, statusSelector, loadItems } from '../../../app/model/menuItemsSlice'

import * as Icons from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import Spinner from 'react-bootstrap/Spinner'
import Alert from 'react-bootstrap/Alert'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import BootstrapTable from 'react-bootstrap-table-next'
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css'

import { currencyFormatter } from '../../../app/utils'
import Button from '../../presentational/Button'

import styles from './MenuItemsList.module.css'

const MenuItemsList = () => {
    const dispatch = useDispatch();

    const menuItems = useSelector(menuItemsSelector);
    const itemsStatus = useSelector(statusSelector);

    useEffect(() => {
        itemsStatus.init && dispatch(loadItems());
    }, [dispatch, itemsStatus]);

    // TODO: move container to the upper component. List only should be here.
    return (
        <Container className="bg-light mt-4 p-4">
            <Row>
                <Col>
                    <h2 className="mb-4">Блюда и напитки</h2>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Button className="mb-4" as={Link} to="/menu/items/add">
                        <FontAwesomeIcon icon={Icons.faPlus} /> Новая позиция
                    </Button>
                </Col>
            </Row>
            <Row>
                <Col>
                    {itemsStatus.loading
                        ? (
                            <Spinner animation="border" role="status">
                                <span className="sr-only">Загрузка данных...</span>
                            </Spinner>
                        ) : menuItems.length
                            ? (
                                <BootstrapTable bootstrap4
                                                classes="bg-white"
                                                headerClasses="text-center"
                                                keyField='id'
                                                data={ menuItems }
                                                columns={[
                                                    {
                                                        dataField: 'id',
                                                        text: '№',
                                                        headerClasses: styles["n-column"],
                                                        classes: 'text-center',
                                                        formatter: (cellContent, row, rowIndex) => (
                                                            rowIndex + 1
                                                        ),
                                                    }, {
                                                        dataField: 'title',
                                                        text: 'Наименование',
                                                        sort: true,
                                                    }, {
                                                        dataField: 'price',
                                                        text: 'Цена',
                                                        sort: true,
                                                        headerClasses: 'w-25',
                                                        classes: 'text-right',
                                                        formatter: (cellContent) => currencyFormatter(cellContent),
                                                    }
                                                ]}
                                />
                            ) : (
                                <Alert variant="primary">
                                    Нет позиций в меню. Сначала <Link to="/menu/items/add">добавьте</Link> блюда, напитки и другие позиции меню.
                                </Alert>
                            )
                    }
                </Col>
            </Row>
        </Container>
    );
};

export default MenuItemsList;
