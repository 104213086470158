import React from 'react'

import { useParams } from 'react-router-dom'

import MenuPageEditForm from './EditForm'
import MenuPageList from './List'

const MenuPageContainer = () => {
    const { id } = useParams();

    if (id) {
        return (
            <MenuPageEditForm id={id} />
        );
    }

    return (
        <MenuPageList />
    );
};

export default MenuPageContainer;
