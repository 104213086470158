import React from 'react'
import { Link } from 'react-router-dom'

import { useSelector } from 'react-redux'
import { selectMenuPages } from '../../../app/model/menuPagesSlice'

import * as Icons from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import ListGroup from 'react-bootstrap/ListGroup'
import Alert from 'react-bootstrap/Alert'

import Button from '../../presentational/Button'

const MenuPageList = () => {
    const componentRoute = '/menu/pages';

    const menuPages = useSelector(selectMenuPages);
    return (
        <Container className="bg-light mt-4 p-4">
            <Row>
                <Col>
                    <h2 className="mb-4">Страницы меню</h2>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Button className="mb-4" as={Link} to={`${componentRoute}/add`}>
                        <FontAwesomeIcon icon={Icons.faPlus} /> Новая страница
                    </Button>
                </Col>
            </Row>
            <Row>
                <Col>
                    {menuPages.length
                        ? <ListGroup>
                            {menuPages.map((page, index) => (
                                <ListGroup.Item key={'menu-page-item-' + index}
                                                action as={Link} to={`${componentRoute}/${page.id}`}
                                >
                                    <Row>
                                        <Col sm={10} className="py-1">
                                            {index + 1}. {page.title} (Позиций: {page.items.length})
                                        </Col>
                                        <Col sm={2} className="text-right">
                                        </Col>
                                    </Row>
                                </ListGroup.Item>
                            ))}
                        </ListGroup>
                        : <Alert variant="primary">
                            Нет страниц в меню. Сначала <Link to="/menu/pages/add">добавьте страницы</Link>.
                        </Alert>
                    }
                </Col>
            </Row>
        </Container>
    );
};

export default MenuPageList;
