import React from 'react'
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import { BrowserRouter, Link, Route, Switch } from 'react-router-dom'

import MenuItemsList from './components/MenuItems/List'
import MenuItemEditForm from './components/MenuItems/EditForm'
import MenuPageContainer from './components/MenuPages'
import MenuPageView from './components/MenuPages/View'

import logo from './logo.svg'
import 'bootstrap/dist/css/bootstrap.min.css' // Importing the Bootstrap CSS
import './App.css'

function App() {
    return (
        <BrowserRouter>
            <div className="App">

                <Navbar sticky="top" bg="light" expand="lg">
                    <Navbar.Brand>
                        <img src={logo} className="App-logo" alt="logo" />
                        ncmenu
                    </Navbar.Brand>
                    <Nav className="mr-auto">
                        <Nav.Link as={Link} to="/view">Просмотр</Nav.Link>
                        <Nav.Link as={Link} to="/menu/pages">Страницы</Nav.Link>
                        <Nav.Link as={Link} to="/menu/items/list">Блюда и напитки</Nav.Link>
                    </Nav>
                </Navbar>

                <Switch>
                    <Route exact={true} path="/" render={() => (
                        <header className="App-header">
                            <p>Нет страниц в меню. Сначала <Link to="/menu/pages/add">добавьте страницы</Link>.</p>
                        </header>
                    )} />
                    <Route path="/view" render={() => (
                        <MenuPageView />
                    )} />
                    <Route path="/menu/pages/:id" render={() => (
                        <MenuPageContainer />
                    )} />
                    <Route path="/menu/pages" render={() => (
                        <MenuPageContainer />
                    )} />
                    <Route path="/menu/items/list" render={() => (
                        <MenuItemsList />
                    )} />
                    <Route path="/menu/items/add" render={() => (
                        <MenuItemEditForm />
                    )} />
                </Switch>

            </div>
        </BrowserRouter>
    );
}

export default App;
