import { configureStore } from '@reduxjs/toolkit'
import menuItemsReducer from './model/menuItemsSlice'
import menuPagesReducer from './model/menuPagesSlice'

export default configureStore({
    reducer: {
        menuItems: menuItemsReducer,
        menuPages: menuPagesReducer,
    },
    devTools: (process.env.NODE_ENV === 'development'),
});
