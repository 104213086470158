import { API_BASE_URI } from './config'

const apiUri = (path = '/') => API_BASE_URI + (path.indexOf('/') === 0 ? '' : '/') + path;

const responseHandler = (response, expectedCode = 200) => {
    if (response.status !== expectedCode) {
        // TODO: rework error handling because response body may contain error message
        return Promise.reject(`HTTP status is ${response.status}, but ${expectedCode} is expected.`);
    }
    return response.json();
};

export const STATUS_ERROR = 'error';
export const STATUS_INIT = 'init';
export const STATUS_LOADING = 'loading';
export const STATUS_READY = 'ready';

export const apiWrapper = () => {

    return {

        fetchMenuItems: () => {
            return fetch(apiUri('/menu/items'), {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                },
            })
                .then(response => responseHandler(response));
        },

    };
};
