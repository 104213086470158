import React, { useState } from 'react'
import { Redirect } from 'react-router-dom'

import { useDispatch } from 'react-redux'
import { addItem } from '../../../app/model/menuItemsSlice'

import { Formik } from 'formik'
import * as Yup from 'yup'

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'

import Button from '../../presentational/Button'

const MenuItemEditForm = () => {
    const dispatch = useDispatch();
    const [processing, setProcessing] = useState(false);
    const [backToList, setBackToList] = useState(false);

    const handleSubmit = values => {
        setProcessing(true);
        dispatch(addItem(values));
        setBackToList(true);
    };

    if (backToList) {
        return <Redirect to='/menu/items/list' />
    }

    return (
        <Container className="bg-light mt-4 p-4">
            <Row>
                <Col>
                    <h2 className="mb-4">Новая позиция в меню</h2>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Formik onSubmit={handleSubmit}
                            initialValues={{
                                title: '',
                                price: '',
                            }}
                            validationSchema={Yup.object({
                                title: Yup.string()
                                    .max(40, 'Не более 40 символов')
                                    .required('Обязательное поле'),
                                price: Yup.number()
                                    .positive('Цена не может быть отрицательной')
                                    .required('Обязательное поле'),
                            })}
                    >
                        {formik => (
                            <Form noValidate onSubmit={formik.handleSubmit}>
                                <Form.Group controlId="title">
                                    <Form.Label>Название позиции</Form.Label>
                                    <Form.Control {...formik.getFieldProps('title')}
                                                  type="text"
                                                  placeholder={'Новое блюдо, десерт или написток'}
                                                  isInvalid={!!(formik.touched.title && formik.errors.title)}
                                                  disabled={processing}
                                    />
                                    <Form.Control.Feedback type="invalid">{formik.errors.title}</Form.Control.Feedback>
                                    <Form.Text className="text-muted">Например: &laquo;Шашлык&raquo;</Form.Text>
                                </Form.Group>
                                <Form.Group controlId="price">
                                    <Form.Label>Цена</Form.Label>
                                    <Form.Control {...formik.getFieldProps('price')}
                                                  type="text"
                                                  isInvalid={!!(formik.touched.price && formik.errors.price)}
                                                  disabled={processing}
                                    />
                                    <Form.Control.Feedback type="invalid">{formik.errors.price}</Form.Control.Feedback>
                                    <Form.Text className="text-muted">Цена за одну порцию</Form.Text>
                                </Form.Group>
                                <Form.Group>
                                    <Button type="submit" processing={processing} processingTitle="В процессе...">
                                        Добавить позицию
                                    </Button>
                                </Form.Group>
                            </Form>
                        )}
                    </Formik>
                </Col>
            </Row>
        </Container>
    );
};

export default MenuItemEditForm;
