import React from 'react'
import PropTypes from 'prop-types'
import BootstrapButton from 'react-bootstrap/Button'
import Spinner from 'react-bootstrap/Spinner'

const Button = ({ children, processing, processingTitle, ...props }) => (
    <BootstrapButton disabled={processing}
                     {...props}
    >
        {
            !processing ? children :
            <>
                <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                />
                &nbsp;
                {processingTitle}
            </>
        }
    </BootstrapButton>
);

Button.propTypes = {
    children: PropTypes.node.isRequired,
    type: PropTypes.string,
    onClick: PropTypes.func,
    processing: PropTypes.bool,
    processingTitle: PropTypes.string,
};

export default Button;
