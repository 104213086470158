import { createSlice } from '@reduxjs/toolkit'
import { STATUS_INIT, STATUS_LOADING, STATUS_READY, STATUS_ERROR, apiWrapper } from '../api'

export const menuItemsSlice = createSlice({
    name: 'menuItems',
    initialState: {
        status: STATUS_INIT,
        items: [],
    },
    reducers: {
        startLoading: state => {
            state.status = STATUS_LOADING;
        },
        setItems: (state, { payload }) => {
            state.items = payload;
            state.status = STATUS_READY;
        },
        setError: state => {
            state.status = STATUS_ERROR;
        },
        addItem: (state, { payload }) => {
            state.items.push({
                ...payload,
                id: state.items.length + 1,
            });
        },
    },
});

export const { startLoading, setItems, setError, addItem } = menuItemsSlice.actions;

export const menuItemsSelector = state => state.menuItems.items;

export const statusSelector = state => ({
    error: state.menuItems.status === STATUS_ERROR,
    init: state.menuItems.status === STATUS_INIT,
    loading: state.menuItems.status === STATUS_LOADING,
    ready: state.menuItems.status === STATUS_READY,
});

export default menuItemsSlice.reducer;

export const loadItems = () => {
    return async (dispatch) => {
        dispatch(startLoading());

        try {
            const data = await apiWrapper().fetchMenuItems();
            dispatch(setItems(data));
        } catch (error) {
            dispatch(setError());

            // TODO: remove this debug output and handle the error
            if (error instanceof TypeError) {
                console.log(error.name, error.message);
            }
            return Promise.reject(error);
        }

        return Promise.resolve();
    };
};
